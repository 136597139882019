<template>
    <form v-click-outside="closeSearchResults" @click="showSearchResults" class="mt-6 w-full flex justify-center">
        <div class="box py-3 px-4 flex justify-between w-full max-w-[790px]">
            <input v-model="searchQuery" type="text" class="w-3/4 text-base md:text-lg" ref="input"  placeholder="Zadajte název služby" />
            <button type="button" class="button">
                Hledat
            </button>
        </div>


        <div v-if="hasResults" class="search-results" :class="{hidden: !searchResultsIsVisible}">
            <ul v-if="results.subCategories.length">
                <h4>Podkategórie</h4>
                <li v-for="(subCategory,index) in results.subCategories" :key="index">
                    <a :href="`/kategoria/${subCategory.category.slug}/${subCategory.slug}`">{{subCategory.name}}</a>
                </li>
            </ul>

            <ul v-if="results.services.length">
                <h4>Služby</h4>
                <li v-for="(service,index) in results.services" :key="index">
                    <a :href="`/sluzba/${service.subcategory.category.slug}/${service.subcategory.slug}/${service.slug}`">{{service.subcategory.name}} > {{service.name}}</a>
                </li>
            </ul>
        </div>
    </form>
</template>

<script>
import {debounce} from 'lodash';
import axios from 'axios';
import vClickOutside from 'click-outside-vue3'


export default {
    name: "Searchbar",
    directives: {
        clickOutside: vClickOutside.directive
    },
    data() {
        return {
            searchResultsIsVisible: false,
            searchQuery: '',
            results: {
                subCategories: [],
                services: []
            }
        }
    },

    watch: {
        searchQuery(val) {
            this.search(val);
        },

        hasResults(val) {
           this.searchResultsIsVisible = val;
        }
    },

    computed: {
        hasResults() {
            return !!(this.results.subCategories.length || this.results.services.length);
        }
    },

    methods: {

        // Close search results
        closeSearchResults() {
            if (this.searchResultsIsVisible) {
                this.searchResultsIsVisible = false;
            }
        },

        showSearchResults() {

            this.$refs.input.focus();
            this.searchResultsIsVisible = true;
        },

        // Search
        search: debounce(function(val) {
            if (this.searchQuery.length > 2) {
                axios.post('/search/results', {
                    search: val
                }).then((response) => {
                     this.results.subCategories = response.data.searchedSubcategories;
                     this.results.services = response.data.searchedServices;
                })
            } else {
                this.results.subCategories = [];
                this.results.services = [];
            }
            },500)

    }
}

</script>

<style lang="scss" scoped>
@import 'resources/sass/abstracts/_mixins.scss';
@import 'resources/sass/abstracts/_variables.scss';
form {
    position: relative;
    max-width: 790px;
    z-index: 1000;
    input {
        cursor: pointer;
    }
    button {
        &:hover {
            background-color: $color-secondary !important;
        }
        @media(max-width: 540px) {
            font-size: rem(13px);
            padding: 20px 10px;
        }
    }
}
.search-results {
    width: 100%;
    padding:  5px 20px;
    border: 1px solid #D1D5DB;
    border-top: none;
    max-height: 400px;
    background-color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top: 80px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    overflow: auto;
    @media(max-width: 540px) {
       max-height: 300px;
    }

    ul {
        margin-bottom: 10px;
        h4 {
            font-size: rem(17px);
            font-weight: 700;
            margin-bottom: 5px;
        }
        li {
            margin-bottom: 7px;
            @media(max-width: 540px) {
                font-size: rem(13px);
            }

            a {
                display: block;
                padding: 3px 10px;
                background-color: #f8f8f8;
                border-radius: 4px;
            }
            a:hover {
                text-decoration: underline !important;
            }
        }
    }
    h3 {
        font-weight: 700;
        font-size: rem(18px);
        margin-bottom: 5px;
    }
}
</style>
