<template>

    <div class="reviews-sidebar">
        <h3>{{averageRating}}</h3>
        <div class="rating flex" :class="{'no-rating': String(averageRating).charAt(0) === '0'}">
            <img v-for="(rating,index) in 5" :key="index" :class="{checked: String(averageRating).charAt(0) === String(index + 1)}" src="/svg/icons/icon-star.svg" width="32" alt="Icon">
        </div>

        <small>Hodnotilo {{companyCountRatings}} zákazníků</small>
        <div class="rating-progressbar flex">
            <span>5 hvězd</span>
            <progress :value="getCountRating(5)"></progress>
        </div>

        <div class="rating-progressbar flex">
            <span>4 hvězdy</span>
            <progress :value="getCountRating(4)"></progress>
        </div>

        <div class="rating-progressbar flex">
            <span>3 hvězdy</span>
            <progress :value="getCountRating(3)"></progress>
        </div>

        <div class="rating-progressbar flex">
            <span>2 hvězdy</span>
            <progress :value="getCountRating(2)"></progress>
        </div>

        <div class="rating-progressbar flex">
            <span>1 hvězda</span>
            <progress :value="getCountRating(1)"></progress>
        </div>

    </div>


    <div class="reviews-content">
        <div class="flex add-review">
            <button v-if="!isVisible" type="button" class="button flex" @click="visibleAddReview"><img src="/svg/icons/icon-plus.svg" alt="Icon">Přidat recenzi</button>
        </div>

        <div v-for="service in companyServices" :key="service.id">
            <div v-for="review in service.reviews" :key="review.id" class="review-item card-box">
                <h5>{{review.name}} {{ review.surname[0] }}.</h5>
                <p>{{review.body}}</p>
                <div class="flex">
                    <div class="rating flex">
                        <img src="/svg/icons/icon-star.svg" :class="{checked: review.rating === 1}" width="20" alt="Icon">
                        <img src="/svg/icons/icon-star.svg" :class="{checked: review.rating === 2}" width="20" alt="Icon">
                        <img src="/svg/icons/icon-star.svg" :class="{checked: review.rating === 3}" width="20" alt="Icon">
                        <img src="/svg/icons/icon-star.svg" :class="{checked: review.rating === 4}" width="20" alt="Icon">
                        <img src="/svg/icons/icon-star.svg" :class="{checked: review.rating === 5}" width="20" alt="Icon">

                        <span>({{review.rating}})</span>
                        <span v-if="review.get_answers.length > 0" @click="toggleAnswerWrap(`answerWrap`+review.id)" class="ml-3 hover-pointer">Odpovědi ({{review.get_answers.length}})</span>
                        <span v-if="isAuthCompany" @click="toggleAnswerFromWrap(`answerFromWrap`+review.id)" class="ml-3 hover-pointer">Odpovědět</span>
                    </div>

                    <small>Přidané - {{formatDate(review.created_at)}}</small>
                </div>

                <div v-if="review.get_answers.length > 0">
                    <div
                        v-if="answerWrapIdShow === `answerWrap`+review.id"
                        class="w-full">
                        <div v-for="answer in review.get_answers" :key="`answer`+answer.id" class="card-box--sm review-item card-box w-full">
                            <h5 class="mb-2">{{answer.name}}</h5>
                            <p class="mb-2">{{answer.body}}</p>
                            <div class="w-full text-right">
                                <small>Přidané - {{formatDateTime(answer.created_at)}}</small>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    v-if="isAuthCompany"
                >
                    <div
                        v-if="answerFormWrapIdShow === `answerFromWrap`+review.id"
                        class="w-full add-review-form">
                        <h5>Odpovědět na recenzi</h5>
                        <form @submit.prevent="sendReviewAnswer(review.id)">
                            <div>
                                <label for="body_review">Správa:</label>
                                <textarea
                                    rows="3"
                                    v-model="formReviewAnswer.body"
                                    id="body_review" name="body"></textarea>
                                <span v-if="v$.formReviewAnswer.body.$error" class="error">{{v$.formReviewAnswer.body.$errors[0].$message}}</span>
                            </div>

                            <br>
                            <button type="submit" class="button">Odpovědět</button>
                            <button type="button" class="button red-bg ml-4" @click.prevent="resetFormAnswer(`answerFromWrap`+review.id)">Zrušit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <!-- add review -->
        <div v-if="isVisible" class="add-review-form">
            <h5>Přidat recenzi</h5>
            <form @submit.prevent="sendReview">
                <div class="grid">
                    <div>
                        <label for="firstName">Křestní jméno:</label>
                        <input v-model="addReview.firstName" type="text" id="firstName" name="firstName" @input="delayTouch(v$.addReview.firstName)">
                        <span v-if="v$.addReview.firstName.$error" class="error">{{v$.addReview.firstName.$errors[0].$message}}</span>
                    </div>

                    <div>
                        <label for="lastName">Příjmení:</label>
                        <input v-model="addReview.lastName" type="text" id="lastName" name="lastName" @input="delayTouch(v$.addReview.lastName)">
                        <span v-if="v$.addReview.lastName.$error" class="error">{{v$.addReview.lastName.$errors[0].$message}}</span>
                    </div>

                    <div>
                        <label for="email">E-mail:</label>
                        <input v-model="addReview.email" type="email" id="email" name="email" @input="delayTouch(v$.addReview.email)">
                        <span v-if="v$.addReview.email.$error" class="error">{{v$.addReview.email.$errors[0].$message}}</span>
                    </div>

                    <div>
                        <label>Vyberte službu:</label>
                        <vSelect v-model="addReview.selectedService" :options="companyServices" label="name" :clearable="false" :searchable="false"/>
                        <span v-if="v$.addReview.selectedService.$error" class="error">{{v$.addReview.selectedService.$errors[0].$message}}</span>
                    </div>
                </div>

                <div>
                    <label for="message">Zpráva:</label>
                    <textarea class="textarea" v-model="addReview.message" id="message" name="message" @input="delayTouch(v$.addReview.message)"></textarea>
                    <span v-if="v$.addReview.message.$error" class="error">{{v$.addReview.message.$errors[0].$message}}</span>
                </div>

                <label>Přidat hodnocení</label>
                <div class="rating flex">
                    <input v-model="addReview.selectedRating" type="radio" name="rating" id="rating-5" :value="5">
                    <label for="rating-5"></label>
                    <input v-model="addReview.selectedRating" type="radio" name="rating" id="rating-4" :value="4">
                    <label for="rating-4"></label>
                    <input v-model="addReview.selectedRating" type="radio" name="rating" id="rating-3" :value="3">
                    <label for="rating-3"></label>
                    <input v-model="addReview.selectedRating" type="radio" name="rating" id="rating-2" :value="2">
                    <label for="rating-2"></label>
                    <input v-model="addReview.selectedRating" type="radio" name="rating" id="rating-1" :value="1">
                    <label for="rating-1"></label>
                </div>
                <h6>{{addReview.selectedRating || 0}} / 5</h6>
                <span v-if="v$.addReview.selectedRating.$error" class="error">{{v$.addReview.selectedRating.$errors[0].$message}}</span>
                <br>

                <button type="submit" class="button">Přidat</button>
                <button type="button" class="button red-bg ml-4" @click.prevent="resetAddReview">Zrušit</button>
            </form>
        </div>
    </div>
</template>

<script>
import 'vue-select/dist/vue-select.css';
import { useVuelidate } from '@vuelidate/core';
import {required, email, helpers} from "@vuelidate/validators";
import axios from "axios";
const touchMap = new WeakMap();

export default {
    name: "Reviews",
    setup: () => ({ v$: useVuelidate()}),
    props: {
        auth: Number,
        averageRating: Number,
        companyServices: Array,
        companyId: Number,
        companyCountRatings: Number
    },

    data() {

        return {
            csrf:document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            answerWrapIdShow: 'none',
            answerFormWrapIdShow: 'none',
            isVisible: false,
            isAuthCompany: parseInt(this.auth) === parseInt(this.companyId),
            addReview: {
                firstName: '',
                lastName: '',
                email: '',
                message: '',
                selectedService: {},
                selectedRating: null,
                ratings: []
            },
            formReviewAnswer: {
                body: '',
                review_id: '',
            },
        }
    },


    validations() {
        return {
            addReview: {
                firstName: {
                    required: helpers.withMessage('Vyplňte Vaše křestní jméno', required)
                },
                lastName: {
                    required: helpers.withMessage('Vyplňte Vaše přijimení', required)
                },
                email: {
                    required: helpers.withMessage('Vyplňte Váš e-mail', required),
                    email: helpers.withMessage('Neplatný e-mail', email)
                },
                message: {
                    required: helpers.withMessage('Vyplňte zprávu', required)
                },
                selectedService: {
                    required: helpers.withMessage('Vyberte službu kterou chcete hodnotit', required)
                },
                selectedRating: {
                    required: helpers.withMessage('Přidejte hodnocení',required)
                }

            },
            formReviewAnswer: {
                body: {
                    required: helpers.withMessage('Vyplňte zprávu', required)
                },
            }
        }
    },

    methods: {
        toggleAnswerFromWrap(idAnswerWrap) {
            this.formReviewAnswer.body = '';
            this.formReviewAnswer.review_id = null;

            if (idAnswerWrap === this.answerFormWrapIdShow) {
                this.answerFormWrapIdShow = 'none';
            }
            else {
                this.answerFormWrapIdShow = idAnswerWrap;
            }
        },

        toggleAnswerWrap(idAnswerWrap) {
            if (idAnswerWrap === this.answerWrapIdShow) {
                this.answerWrapIdShow = 'none';
            }
            else {
                this.answerWrapIdShow = idAnswerWrap;
            }
        },

        formatDate(date) {
            const options = { year: 'numeric', month: 'long', day: 'numeric' }
            return new Date(date).toLocaleDateString('sk', options)
        },

        formatDateTime(date) {
            const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }
            return new Date(date).toLocaleString('sk', options)
        },

        sendReview() {
            this.v$.addReview.$validate();
            if(!this.v$.addReview.$error) {
                axios.post(`/firma/${this.companyId}`, this.addReview).then(()=>{
                    this.$swal({
                        title: 'Děkujeme!',
                        text: 'Hodnocení bylo přidané.',
                        timerProgressBar: true,
                        timer: 2500,
                        icon: 'success',
                        background: '#308102',
                        color: '#fff',
                        showConfirmButton: false
                    })
                    this.resetForm();
                    window.scrollTo(0,0);
                }).catch((e)=>e)

            } else {
                this.$nextTick(() => {
                    const domRect = document.querySelector('.error').getBoundingClientRect();
                    window.scrollTo({
                            top: domRect.top + document.documentElement.scrollTop -65,
                            left: domRect.left + document.documentElement.scrollLeft - 115,
                            behavior: 'smooth'
                        }
                    );
                });

                this.$swal({
                    title: 'Chyba!',
                    text: 'Vyplňte všechny povinné údaje.',
                    toast: true,
                    position: 'top-end',
                    timerProgressBar: true,
                    timer: 2500,
                    icon: 'error',
                    background: '#ce0000',
                    color: '#fff',
                    showConfirmButton: false
                })
            }
        },

        resetForm() {
            this.v$.$reset();
            this.addReview.firstName = '';
            this.addReview.lastName = '';
            this.addReview.email = '';
            this.addReview.message = '';
            this.addReview.selectedService = {};
            this.addReview.selectedRating = null;
            this.isVisible = false;
        },

        visibleAddReview() {
            this.isVisible = true;
            this.$nextTick(() => {
                const domRect = document.querySelector('.add-review-form').getBoundingClientRect();
                window.scrollTo({
                        top: domRect.top + document.documentElement.scrollTop -40,
                        left: domRect.left + document.documentElement.scrollLeft - 115,
                        behavior: 'smooth'
                    }
                );
            });
        },

        resetAddReview() {
            this.resetForm();
            this.$nextTick(() => {
                const domRect = document.querySelector('.add-review').getBoundingClientRect();
                window.scrollTo({
                    top: domRect.top + document.documentElement.scrollTop -40,
                    left: domRect.left + document.documentElement.scrollLeft - 115,
                    behavior: 'smooth'
                }
                );
            });
        },

        // Submit form
        submitForm() {
            this.v$.$validate();
            if (!this.v$.$error) {
                this.resetForm();
                this.$nextTick(() => {
                    const domRect = document.querySelector('.add-review').getBoundingClientRect();
                    window.scrollTo({
                            top: domRect.top + document.documentElement.scrollTop -40,
                            left: domRect.left + document.documentElement.scrollLeft - 115,
                            behavior: 'smooth'
                        }
                    );
                });
            } else {
                console.log('validation not sucessful')
            }
        },

        // Show error message on input delay
        delayTouch(v$) {
            v$.$reset();
            if (touchMap.has(v$)) {
                clearTimeout(touchMap.get(v$));
            }
            touchMap.set(v$, setTimeout(v$.$touch, 1000));
        },

      getCountRating(ratingNum) {
            if (this.companyCountRatings) {

                let allRatings = [];
                this.companyServices.forEach(service=> {
                    service.reviews.forEach(review => {
                        allRatings.push(review.rating)
                    })
                });


                const ratingCount = allRatings.filter(rating=> rating === ratingNum).length;
                return (ratingCount / this.companyCountRatings).toFixed(2);
            } else {
                return 0;
            }
        },

        sendReviewAnswer(reviewId) {
            this.formReviewAnswer.review_id = reviewId;

            this.v$.formReviewAnswer.$validate();
            if(!this.v$.formReviewAnswer.$error) {
                axios.post(`/firma/${this.companyId}/recenzia/odpoved`, this.formReviewAnswer).then((response)=>{
                    this.$swal({
                        title: 'Děkujeme!',
                        text: 'Odpoveď byla zaznamenána.',
                        timerProgressBar: true,
                        timer: 2500,
                        icon: 'success',
                        background: '#308102',
                        color: '#fff',
                        showConfirmButton: false
                    });
                    this.resetFormAnswer();
                    window.location.reload();
                }).catch((e)=>
                {
                    this.$swal({
                        title: 'Chyba!',
                        text: '',
                        toast: true,
                        position: 'top-end',
                        timerProgressBar: true,
                        timer: 2500,
                        icon: 'error',
                        background: '#ce0000',
                        color: '#fff',
                        showConfirmButton: false
                    })
                })

            } else {
                this.$swal({
                    title: 'Chyba!',
                    text: '',
                    toast: true,
                    position: 'top-end',
                    timerProgressBar: true,
                    timer: 2500,
                    icon: 'error',
                    background: '#ce0000',
                    color: '#fff',
                    showConfirmButton: false
                })
            }
        },

        resetFormAnswer(answerFromWrapId) {
            this.v$.formReviewAnswer.$reset();
            this.toggleAnswerFromWrap(answerFromWrapId);
        },
    },

}
</script>

<style lang="scss" scoped>
@import 'resources/sass/abstracts/_mixins.scss';
@import 'resources/sass/abstracts/_variables.scss';

.add-review-form {
    margin-top: 50px;
    background-color: lighten(#f5f4f2,2%);
    padding: 30px;
    border-radius: 6px;
    @media (max-width: 540px) {
        padding: 15px;
    }

    .rating {
        margin-top: 5px;
        flex-direction: row-reverse;
        justify-content: start;
        position: relative;
        left: -4px;
        input {
            display: none;
            &:checked ~ label, &:checked ~ label ~ label {
                background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23fcd93a' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
            }

            &:not(:checked) ~ label:hover,&:not(:checked) ~ label:hover ~ label {
                background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23d8b11e' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
            }
        }

        label {
            cursor: pointer;
            width: 40px;
            height: 40px;
            margin-top: auto;
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23e3e3e3' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 76%;
            transition: .3s;
        }
    }
    button {
        margin-top: 10px;
        &.red-bg {
            background-color: #d13830;
            color: $color-white;
            border-color: transparent;
            &:hover {
                background-color: darken(#d13830,5%);
            }
        }
    }
    h5 {
        font-weight: 600;
        margin-bottom: 15px;
        font-size: rem(20px);
    }


    h6 {
        font-weight: 700;
        font-size: rem(20px);
    }

    .error {
        color:#d13830;
        font-size: rem(14px);
        font-weight: 600;
    }

    label {
            text-transform: uppercase;
            font-size: rem(13px);
            font-weight: 600;
            color: #6B7280;
            letter-spacing: 2px;
        }
        input {
            width: 100%;
            height: 56px;
            border: 1px solid #DDE3E8;
            outline: none;
            border-radius: 6px;
            padding: 0 15px;
            font-size: rem(14px);
            &:focus {
                border-color: $color-secondary;
            }
        }

    textarea {
        width: 100%;
        resize: none;
        border: 1px solid #D1D5DB;
        outline: none;
        border-radius: 6px;
        padding: 15px;
        font-size: rem(14px);
        &:focus {
            border-color: $color-secondary;
        }
    }
    .textarea {
        height: 150px;
    }
    }
    .grid {
        font-size: rem(20px);
        gap: 15px;
        margin-bottom: 15px;
        div {
            grid-template-columns: 1fr 1fr;
            @media (max-width: 768px) {
                grid-template-columns: 1fr;
            }
        }
    }

.review-item {
    margin-top: 26px;
    h5 {
        font-weight: 500;
        margin-bottom: 20px;
    }
    p {
        color: #6B7280;
        margin-bottom: 20px;
    }
    & > .flex {
        justify-content: space-between;
        align-items: center;
        @media (max-width: 540px) {
            flex-direction: column;
            align-items: start;
        }
    }

    .rating {
        margin: 0;
        span {
            font-weight: 600;
            position: relative;
            top: 2px;
        }
    }
    .rating img {
        margin-right: 7px;
    }

    small {
        color: #9CA3AF;
        font-size: rem(14px);
        @media (max-width: 540px) {
            margin-top: 6px;
        }
    }

    &.card-box--sm {
        margin-top: 16px;
    }
}

.add-review {
    justify-content: end;
    button {
        align-items: center;
        img {
            margin-right: 10px;
        }
    }
}

.reviews-sidebar {
    .rating-progressbar {
        justify-content: space-between;
        margin-bottom: 19px;
        @media (max-width: 992px) {
            flex-direction: column;
        }
        span {
            font-weight: 600;
            font-size: rem(14px);
        }
    }

    progress[value] {
        flex: 0 0 134px;
        height: 24px;
        -webkit-appearance: none;
        appearance: none;
        @media (max-width: 992px) {
            width: 100%;
            flex: auto;
        }
        &::-webkit-progress-bar {
            border-radius: 3px;
            background-color: #E5E7EB;
        }

        &::-webkit-progress-value {
            border-radius: 3px;
            background-color: #000;
        }
    }

    h3 {
        color: #000;
        font-size: rem(42px);
        font-weight: 700;
    }

    small {
        font-size: rem(12px);
        color: #2C2C2C;
        display: block;
        margin-bottom: 30px;
    }
}

.rating {
    margin: 10px 0  10px 0;
    img {
        margin-right: 12px;
        &:last-child {
            margin-right: 0;
        }
    }
}

.hover-pointer {
    &:hover {
        cursor: pointer;
    }
}

</style>
