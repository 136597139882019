import "./src/suggestion-for-impovement";
import "./src/header";
import "./src/scroll";
import "./src/scroll-up";
import "./src/vh-check";
import "./src/slide-down";
import "./src/contact-form";
import '@fortawesome/fontawesome-free/js/all';
import { createApp } from 'vue';
import vSelect from 'vue-select';
import Reviews from "./components/Reviews.vue";
import AddCompanyFormAdmin from "./components/AddCompanyFormAdmin";
import AddCompanyForm from "./components/AddCompanyForm";
import SubscriptionForm from "./components/SubscriptionForm";
import Searchbar from "./components/Searchbar";
import VerificationForm from "./components/VerificationForm";
const app = createApp({});

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import '@fortawesome/fontawesome-free/js/all';

// Vue components
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('AddCompanyFormAdmin', AddCompanyFormAdmin);
app.component('AddCompanyForm', AddCompanyForm);
app.component('SubscriptionForm', SubscriptionForm);
app.component('Reviews', Reviews);
app.component('Searchbar', Searchbar);
app.component('VerificationForm', VerificationForm);
app.component('vSelect', vSelect);

app.use(VueSweetalert2);

// Mount app
app.mount('#site-main');

import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();
