
<template>
    <form @submit.prevent="registration">
        <input type="hidden" name="_token" :value="csrf" />
        <div class="form-group">
            <label for="company_name">Obchodní název firmy*</label>
            <input
                v-model="form.name"
                type="text"
                name="company_name"
                id="company_name"
                @input="delayTouch(v$.form.name)"
            />
            
            <span v-if="v$.form.name.$error" class="error">{{
                v$.form.name.$errors[0].$message
            }}</span>
        </div>
        <div class="form-group form-group-textarea">
            <label for="description">Popis činnosti firmy</label>
            <textarea
                v-model="form.description"
                name="description"
                id="description"
                @input="delayTouch(v$.form.description)"
            >
        </textarea>
            <span v-if="v$.form.description.$error" class="error">{{
                v$.form.description.$errors[0].$message
            }}</span>
        </div>

        <div class="form-group">
            <label for="ico">IČO *</label>
            <input
                v-model="form.ico"
                type="text"
                name="ico"
                id="ico"
                @input="delayTouch(v$.form.ico)"
            />
            <span v-if="v$.form.ico.$error" class="error">{{
                v$.form.ico.$errors[0].$message
            }}</span>
        </div>
        <div class="form-group">
            <label for="email">Email *</label>
            <input
                v-model="form.email"
                type="email"
                name="email"
                id="email"
                @input="delayTouch(v$.form.email)"
            />
            <span v-if="v$.form.email.$error" class="error">{{
                v$.form.email.$errors[0].$message
            }}</span>
        </div>

<!--        <div class="form-group" v-if="this.registration_page">-->
<!--            <label for="password">Heslo</label>-->
<!--            <input-->
<!--                v-model="form.password"-->
<!--                type="password"-->
<!--                name="password"-->
<!--                id="password"-->
<!--                maxlength="30"-->
<!--                @input="delayTouch(v$.form.password)"-->
<!--            />-->
<!--            <span v-if="v$.form.password.$error" class="error">{{-->
<!--                v$.form.password.$errors[0].$message-->
<!--            }}</span>-->
<!--        </div>-->

        <div class="form-group">
            <label for="web">Web</label>
            <input
                v-model="form.web"
                type="url"
                name="web"
                id="web"
                placeholder="https://"
            />
            <span v-if="v$.form.web.$error" class="error">{{
                v$.form.web.$errors[0].$message
            }}</span>
        </div>

        <div class="form-group">
            <label for="street_address">Adresa *</label>
            <input
                v-model="form.street"
                type="text"
                name="street_address"
                id="street_address"
                @input="delayTouch(v$.form.street)"
            />
            <span v-if="v$.form.street.$error" class="error">{{
                v$.form.street.$errors[0].$message
            }}</span>
        </div>

<!--        <div class="form-group">-->
<!--            <label for="street_number">Čí­slo ulice *</label>-->
<!--            <input-->
<!--                v-model="form.street_number"-->
<!--                type="text"-->
<!--                name="street_number"-->
<!--                id="street_number"-->
<!--                @input="delayTouch(v$.form.street_number)"-->
<!--            />-->
<!--            <span v-if="v$.form.street_number.$error" class="error">{{-->
<!--                v$.form.street_number.$errors[0].$message-->
<!--            }}</span>-->
<!--        </div>-->

        <div class="form-group">
            <label for="city_helper">Město *</label>
            <v-select
                id="city_helper"
                v-model="selectedCity"
                :options="cities">
                <template #search="{attributes, events}">
                    <input
                        class="vs__search"
                        v-bind="attributes"
                        v-on="events"
                    />
                </template>
                <template #option="{ name, district }">
                    <div class="select-box--option">
                        <p class="select-box--title">{{ name }}</p>
                        <p class="select-box--subtitle">{{ district }}</p>
                    </div>
                </template>
                <template #selected-option="{ name, district }">
                    <div style="display: flex; align-items: baseline">
                        <strong>{{ name }}</strong>
                        <span style="margin-left: 0.2rem">({{ district }})</span>
                    </div>
                </template>
            </v-select>
            <span v-if="v$.form.city.$error" class="error">{{
                v$.form.city.$errors[0].$message}}</span>
        </div>

        <input type="hidden" id="city" name="city" v-model="form.city">
        <input type="hidden" id="city_id" name="city_id" v-model="form.city_id">

        <div v-if="selectedCity" class="form-group">
            <label for="zip_code">PSČ *</label>
            <input
                v-model="form.post_code"
                type="text"
                name="zip_code"
                id="zip_code"
                @input="delayTouch(v$.form.post_code)"
            />
            <span v-if="v$.form.post_code.$error" class="error">{{
                v$.form.post_code.$errors[0].$message
            }}</span>
        </div>

        <div v-if="selectedCity" class="form-group">
            <label for="country">Kraj *</label>
            <input
                readonly
                v-model="form.country"
                type="text"
                name="country"
                id="country"
                @input="delayTouch(v$.form.country)"
            />
            <span v-if="v$.form.country.$error" class="error">{{
                v$.form.country.$errors[0].$message
            }}</span>
        </div>

        <div class="form-group">
            <label for="mobile">Mobil *</label>
            <input
                v-model="form.mobile"
                type="text"
                name="mobile"
                id="mobile"
                @input="delayTouch(v$.form.mobile)"
            />
            <span v-if="v$.form.mobile.$error" class="error">{{
                v$.form.mobile.$errors[0].$message
            }}</span>
                    <a class="helper-trigger" href="javascript:void(0)" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-placement="top" title="Nápověda" data-bs-content="Zadávejte ve formátu: +420 720 000 000"><i class="fa fa-question-circle" aria-hidden="true"></i></a>

        </div>

        <div class="form-group">
            <label for="phone">Telefon</label>
            <input
                v-model="form.phone"
                type="text"
                name="phone"
                id="phone"
                @input="delayTouch(v$.form.phone)"
            />
            <span v-if="v$.form.phone.$error" class="error">{{
                v$.form.phone.$errors[0].$message
            }}</span>
        </div>

        <div class="form-group">
            <label for="google_profile">Google recenze link</label>
            <input
                v-model="form.google_profile"
                type="url"
                name="google_profile"
                id="google_profile"
            />
            <span v-if="v$.form.google_profile.$error" class="error">{{
                v$.form.google_profile.$errors[0].$message
            }}</span>
        </div>

        <div class="form-group">
            <label for="facebook">Facebook</label>
            <input
                v-model="form.facebook"
                type="url"
                name="facebook"
                id="facebook"
                @input="delayTouch(v$.form.facebook)"
            />
            <span v-if="v$.form.facebook.$error" class="error">{{
                v$.form.facebook.$errors[0].$message
            }}</span>
        </div>

        <div class="form-group">
            <label for="instagram">Instagram</label>
            <input
                v-model="form.instagram"
                type="url"
                name="instagram"
                id="instagram"
                @input="delayTouch(v$.form.instagram)"
            />
            <span v-if="v$.form.instagram.$error" class="error">{{
                v$.form.instagram.$errors[0].$message
            }}</span>
        </div>

        <div class="form-group">
            <label for="linkedin">LinkedIn</label>
            <input
                v-model="form.linkedin"
                type="url"
                name="linkedin"
                id="linkedin"
                @input="delayTouch(v$.form.linkedin)"
            />
            <span v-if="v$.form.linkedin.$error" class="error">{{
                v$.form.linkedin.$errors[0].$message
            }}</span>
        </div>

        <div class="form-group">
            <h3>Přidejte služby</h3>
            <div v-if="checkShowNewServiceButton">
                <button
                    v-if="!serviceFormIsVisible"
                    type="button"
                    class="add-service mb-2"
                    @click="showServiceForm"
                >
                    + nová služba
                </button>
            </div>
            <div v-else
                 class="info-wrap mb-2">
                <a
                    target="_blank"
                    :href="subscriptionLink"
                >{{ errorSubscriptionMsg }}</a>
            </div>
            <span v-if="v$.form.addedServices.$error" class="error">{{
                v$.form.addedServices.$errors[0].$message
            }}</span>
        </div>

        <div v-if="serviceFormIsVisible" class="add-service-form">
            <div class="form-group"
                 v-click-outside="closeServiceNameResults"
            >
                <label for="service_name">Název služby</label>
                <input
                    @click="showServiceNameResults"
                    v-model="service.name"
                    type="text"
                    name="service_name"
                    id="service_name"
                    @input="delayTouch(v$.service.name)"
                />
                <div v-if="hasServiceNameResults && serviceNameResultsIsVisibleClick"
                     class="search-results"
                     :class="{hidden: !serviceNameResultsIsVisible}">
                     <ul v-if="serviceResults.length">
                        <li
                            @click="selectServiceName(resultService.name)"
                            v-for="(resultService, index) in serviceResults" :key="`service-`+index">
                            {{resultService.name}}
                        </li>
                    </ul>
                </div>
                <span v-if="v$.service.name.$error" class="error">{{
                    v$.service.name.$errors[0].$message
                }}</span>
            </div>

            <div class="form-group">
                <label>kategorie</label>
                <vSelect
                    v-model="service.category"
                    :options="categories"
                    label="name"
                    :clearable="false"
                    :searchable="false"
                />
                <span v-if="v$.service.category.$error" class="error">{{
                    v$.service.category.$errors[0].$message
                }}</span>
            </div>

            <div v-if="service.category" class="form-group">
                <label>podkategorie </label>
                <vSelect
                    v-model="service.subCategory"
                    :options="service.category.subcategories"
                    label="name"
                    :clearable="false"
                    :searchable="false"
                />
                <span v-if="v$.service.subCategory.$error" class="error">{{
                    v$.service.subCategory.$errors[0].$message
                }}</span>
            </div>

            <div class="form-group">
                <label for="service_price">Cena služby v KČ</label>
                <input
                    v-model="service.price"
                    type="number"
                    name="service_price"
                    id="service_price"
                    @input="delayTouch(v$.service.price)"
                />
                <span v-if="v$.service.price.$error" class="error">{{
                    v$.service.price.$errors[0].$message
                }}</span>
            </div>

            <div class="d-flex">
                <button type="button" class="add" @click="addService">
                    Přidat
                </button>
                <button type="button" class="reset" @click="resetService">
                    Zrušit
                </button>
            </div>
        </div>

        <div v-if="form.addedServices.length" class="added-services">
            <div v-for="(service, index) in form.addedServices" :key="index">
                <h5>
                    <strong>{{ index + 1 }}.</strong>
                </h5>
                <p><strong>Název služby:</strong> {{ service.name }}</p>
                <p><strong>Kategorie:</strong> {{ service.category.name }}</p>
                <p>
                    <strong>Podkategorie:</strong>
                    {{ service.subCategory.name }}
                </p>
                <p><strong>Cena:</strong> {{ service.price }} ‚¬</p>
                <button type="button" @click="removeService(index)">
                    Smazat službu
                </button>
            </div>
        </div>

        <!--div v-if="this.registration_page && form.addedServices.length" class="subscription">
            <h3>Předplatné</h3>
            <div class="form-group">
                <label>Obdobie</label>
                <vSelect
                    v-model="selectedPeriod"
                    :options="period"
                    label="title"
                    :clearable="false"
                    :searchable="false"
                />
                <span v-if="v$.form.period.$error" class="error">{{
                        v$.form.period.$errors[0].$message
                    }}</span>
            </div>

            <input type="hidden" id="period" name="period" v-model="form.period">
            <input type="hidden" id="plan" name="plan" v-model="form.plan">

            <div v-if="selectedPeriod" class="form-group">
                <label for="periodPrice">Cena předplatného</label>
                <input
                    disabled
                    v-model="periodPrice"
                    type="text"
                    id="periodPrice"
                />
            </div>
        </div-->

        <div class="opening-hours">
            <h3>Otevírací hodiny</h3>
            <div class="my-7">
                <div v-for="([name, var_name]) in business" class="flex mb-2">
                    <div class="form-group flex items-center w-[18%]">
                        <strong>{{ name}}</strong>
                    </div>

                    <div class="form-group">
                        <label :for="var_name + `_from`">Od</label>
                        <input
                            v-model="form.business_hours[var_name].from"
                            type="time"
                            :id="var_name + `_from`"
                        />
                    </div>

                    <div class="form-group">
                        <label for="var_name + `_to`">Do</label>
                        <input
                            v-model="form.business_hours[var_name].to"
                            type="time"
                            :id="var_name + `_to`"
                        />
                    </div>
                </div>
            </div>
        </div>

        <h3 class="upload-image-h3">Nahrajte obrázky pro profil vaší firmy</h3>
        <div class="upload-images flex">
            <div class="upload-file">
                <label for="cover_img" class="upload-img">
                    <div class="flex">
                        <img src="/svg/icons/icon-upload.svg" alt="Icon" />
                        <span>nahrát úvodní obrázek</span>
                    </div>
                </label>
                <input
                    type="file"
                    name="cover_img"
                    id="cover_img"
                    ref="coverImg"
                    @change="onChangeCoverImgFileUpload"
                />
                <div v-if="form.uploadedCoverImg" class="file-name">
                    <p>{{ form.uploadedCoverImg.name }}</p>
                </div>
            </div>

            <div class="upload-file">
                <label for="profile_img" class="upload-img">
                    <div class="flex">
                        <img src="/svg/icons/icon-upload.svg" alt="Icon" />
                        <span>nahrát profilový obrázek</span>
                    </div>
                </label>
                <input
                    type="file"
                    name="profile_img"
                    id="profile_img"
                    ref="profileImg"
                    @change="onChangeProfileImgFileUpload"
                />
                <div v-if="form.uploadedProfileImg" class="file-name">
                    <p>{{ form.uploadedProfileImg.name }}</p>
                </div>
            </div>
        </div>

        <div class="mt-8" v-if="this.registration_page">
            <div>
                <input
                    type="checkbox"
                    v-model="form.vop"
                    id="vop"
                />
                <label class="ml-2" for="vop"
                    >Souhlasím se všeobecnými obchodními podmínkami</label
                >
            </div>

            <span v-if="v$.form.vop.$error" class="error">{{
                    v$.form.vop.$errors[0].$message
                }}</span>

            <div>
                <input
                    type="checkbox"
                    v-model="form.personal"
                    id="personal"
                />
                <label class="ml-2" for="personal"
                    >Souhlasím se zásady ochrany osobních údajů</label
                >
            </div>
            <span v-if="v$.form.personal.$error" class="error">{{
                    v$.form.personal.$errors[0].$message
                }}</span>
            <div>
                <input
                    type="checkbox"
                    v-model="form.marketing"
                    id="marketing"
                />
                <label class="ml-2" for="marketing"
                    >Souhlasím se zasíláním marketingových emalu</label
                >
            </div>
        </div>

        <div class="flex justify-center">
            <button type="submit"
                    :disabled="buttonIsLoading"
                    class="button">
                <span v-if="!buttonIsLoading">{{ this.registration_page ? 'Zaregistrovat firmu' : 'Uložit' }}</span><span v-if="buttonIsLoading"><i class="mx-3 fa-lg fa fa-spinner fa-spin loader-icon"></i></span>
            </button>
        </div>
    </form>
</template>

<script>
import "vue-select/dist/vue-select.css";
import { useVuelidate } from "@vuelidate/core";
import {
    required,
    email,
    helpers,
    numeric,
    minLength,
    maxLength,
    url,
    sameAs,
} from "@vuelidate/validators";
import axios from "axios";
import {debounce} from 'lodash';
import vClickOutside from 'click-outside-vue3'

const touchMap = new WeakMap();

function packageData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            packageData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;

        formData.append(parentKey, value);
    }

    return formData;
}

export default {
    setup: () => ({
        v$: useVuelidate(),
    }),
    name: "AddCompanyForm",
    props: {
        cities: Array,
        categories: Array,
        data: Object,
        endpoint: String,
        registration_page: Boolean,
        plans: Array,
        subscription: Object,
        subscriptionLink: String,
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    data() {
        return {
            buttonIsLoading: false,
            serviceFormIsVisible: false,
            csrf: document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute("content"),
            form: this.data,
            business: [
                ['Pondělí', 'monday'],
                ['Úterý', 'tuesday'],
                ['Středa', 'wednesday'],
                ['Čtvrtek', 'thursday'],
                ['Pátek', 'friday'],
                ['Sobota', 'saturday'],
                ['Neděle', 'sunday'],
            ],
            serviceNameResultsIsVisible: false,
            serviceNameResultsIsVisibleClick: false,
            service: {
                name: "",
                category: null,
                subCategory: null,
                price: "",
            },
            //period: [],
            //periodPrice: "",
            errorSubscriptionMsg: "",
            firstLoad: true,
            //selectedPeriod: null,
            selectedCity: null,
            serviceResults: [],
            subscriptionPlan: null
        };
    },
    created() {
        if (!this.registration_page) { this.subscriptionPlan = this.subscription.plan.code; }
        this.setSelectedCity(this.form.city_id);
    },

    watch: {
        selectedCity(value) {
            let newPostCode = this.form.post_code;
            if (!this.firstLoad || this.registration_page) {
                if (value) {
                    this.form.country = value.country;
                    this.form.city_id = value.id;
                    this.form.city = value.name;
                    if (value.postal_code) {
                        newPostCode = value.postal_code;
                    }
                }
                else {
                    this.form.country = null;
                    this.form.city_id = null;
                    this.form.city = null;
                }
            }
            this.firstLoad = false;
            this.form.post_code = newPostCode;
        },

        /*selectedPeriod(value) {
            if (value) {
                this.form.period = value.months;
                this.periodPrice = parseFloat(Math.round((value.price * 1.2) * 100) / 100).toString() + ' ‚¬';
            }
            else {
                this.form.period = null;
                this.periodPrice = "";
            }
        },*/

        'service.name': function (val, oldVal) {
            this.search(val);
        },

        hasServiceNameResults(val) {
            this.serviceNameResultsIsVisible = val;
        },
    },

    computed: {
        hasServiceNameResults() {
            return !!(this.serviceResults.length);
        },
        checkShowNewServiceButton() {
            if (this.registration_page) {
                return true;
            }
            if (this.subscriptionPlan === 'many') {
                return true;
            }
            if ((this.subscriptionPlan === 'one') && (this.form.addedServices.length < 1)) {
                return true;
            }

            this.errorSubscriptionMsg = "Ak chcete pridaĹĄ sluĹľbu je potrebnĂ© aby ste si zkĂşpili predplatnĂ©";

            if (this.subscriptionPlan === 'one') {
                this.errorSubscriptionMsg = "Ak chcete pridaĹĄ ÄŹaÄľĹˇiu sluĹľbu je potrebnĂ© aby ste si zkĂşpili vyĹˇĹˇie predplatnĂ©";
            }

            return false;
        },
    },

    methods: {
        /*updatePeriodPlan(label = null) {
            let plan = null;
            if (label !== null) {
                this.plans.forEach(function (item) {
                    if(item.code.toString() === label.toString()) {
                        plan = item;
                    }
                });
            }
            if (plan) {
                this.form.plan = plan.id;
                this.period = plan.prices;
                if (this.selectedPeriod) {
                    let newPeriod = this.selectedPeriod;
                    let oldPeriod = this.selectedPeriod;
                    this.period.forEach(function (itemPeriod) {
                        if(parseInt(itemPeriod.months) === parseInt(oldPeriod.months)) {
                            newPeriod = itemPeriod;
                        }
                    });
                    this.selectedPeriod = newPeriod;
                }
            }
            else {
                this.form.plan = null;
                this.period = [];
            }
        },

        handlePeriodPlan() {
            if (this.form.addedServices.length === 0) {
                this.updatePeriodPlan();
            }
            else if (this.form.addedServices.length === 1) {
               this.updatePeriodPlan('one');
            }
            else if (this.form.addedServices.length > 1) {
                this.updatePeriodPlan('many');
            }
        },*/

        setSelectedCity(cityId = 0) {
            let city = null;

            this.cities.forEach(function (item) {
                if(item.id === cityId) {
                    city = item;
                }
            });

            this.selectedCity = city;
        },

        onChangeCoverImgFileUpload() {
            this.form.uploadedCoverImg = this.$refs.coverImg.files[0];
        },

        onChangeProfileImgFileUpload() {
            this.form.uploadedProfileImg = this.$refs.profileImg.files[0];
        },

        // Show service form
        showServiceForm() {
            this.serviceFormIsVisible = true;
        },

        // Add service
        addService() {
            this.v$.service.$validate();

            if (!this.v$.service.$error) {
                this.form.addedServices.push({...this.service});
                this.resetService();
                this.$swal({
                    title: "OK",
                    text: "SluĹľba bola pridanĂˇ",
                    toast: true,
                    position: "top-end",
                    timerProgressBar: true,
                    timer: 2500,
                    icon: "success",
                    background: "#308102",
                    color: "#fff",
                    showConfirmButton: false,
                });
            } else {
                this.$swal({
                    title: "Chyba!",
                    text: "Vyplňte všechny potřebné údaje.",
                    toast: true,
                    position: "top-end",
                    timerProgressBar: true,
                    timer: 2500,
                    icon: "error",
                    background: "#ce0000",
                    color: "#fff",
                    showConfirmButton: false,
                });
            }

            // this.handlePeriodPlan();
        },

        // Remove service
        removeService(index) {
            this.form.addedServices.splice(index, 1);
            // this.handlePeriodPlan();
        },

        // Reset service
        resetService() {
            this.service.name = "";
            this.service.category = null;
            this.service.subCategory = null;
            this.service.price = "";
            this.serviceFormIsVisible = false;
            this.v$.service.$reset();
        },

        // Reset form
        resetForm() {
            this.form.description = "";
            this.form.name = "";
            this.form.ico = "";
            this.form.email = "";
            // this.form.password = "";
            this.form.web = "";
            this.form.street = "";
            this.form.street_number = "";
            this.form.city = "";
            this.form.city_id = null;
            this.form.post_code = "";
            this.form.country = "";
            this.form.mobile = "";
            this.form.phone = "";
            this.form.addedServices = [];
            // this.form.plan = "";
            // this.form.period = "";
            this.form.uploadedCoverImg = null;
            this.form.uploadedProfileImg = null;
            this.form.week_open_at = "09:00";
            this.form.week_closed_at = "17:00";
            this.form.weekend_open_at = "09:00";
            this.form.weekend_closed_at = "12:00";
            this.form.vop = false;
            this.form.personal = false;
            this.form.marketing = false;
            this.v$.form.$reset();
        },

        // Registration
        registration() {
            this.buttonIsLoading = true;
            this.v$.form.$validate();
            const formData = new FormData();
            if (!this.v$.form.$error) {
                axios
                    .post(this.endpoint, packageData(formData, this.form), {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            "X-CSRF-TOKEN": this.csrf,
                        },
                    })
                    .then((response) => {
                        if (this.registration_page) {
                            this.$swal({
                                title: "Děkujeme!",
                                text: "Registrace proběhla úspěšně.",
                                timerProgressBar: true,
                                timer: 2500,
                                icon: "success",
                                background: "#308102",
                                color: "#fff",
                                showConfirmButton: false,
                            });
                        }
                        else {
                            this.$swal({
                                title: "Výborně!",
                                text: "Údaje byly úspěšně změněné.",
                                timerProgressBar: true,
                                timer: 2500,
                                icon: "success",
                                background: "#308102",
                                color: "#fff",
                                showConfirmButton: false,
                            });
                        }

                        this.resetForm();
                        this.buttonIsLoading = false;
                        window.location = response.request.responseURL;
                    })
                    .catch((e) => {
                        if (e.response.status === 422) {
                            this.$swal({
                                title: "Chyba!",
                                text: e.response.data.message,
                                timerProgressBar: true,
                                timer: 2500,
                                icon: "error",
                                background: "#ce0000",
                                color: "#fff",
                                showConfirmButton: false,
                            });
                        } else {
                            // Error
                            this.$swal({
                                title: "Chyba!",
                                text: "Vyzkoušejte prosím později",
                                timerProgressBar: true,
                                timer: 2500,
                                icon: "error",
                                background: "#ce0000",
                                color: "#fff",
                                showConfirmButton: false,
                            });
                        }
                        this.buttonIsLoading = false;
                    });
            } else {
                this.$nextTick(() => {
                    const domRect = document
                        .querySelector(".error")
                        .getBoundingClientRect();
                    window.scrollTo({
                        top:
                            domRect.top +
                            document.documentElement.scrollTop -
                            65,
                        left:
                            domRect.left +
                            document.documentElement.scrollLeft -
                            115,
                        behavior: "smooth",
                    });
                });

                this.$swal({
                    title: "Chyba!",
                    text: "Vyplňte všechny potřebné údaje.",
                    toast: true,
                    position: "top-end",
                    timerProgressBar: true,
                    timer: 2500,
                    icon: "error",
                    background: "#ce0000",
                    color: "#fff",
                    showConfirmButton: false,
                });
                this.buttonIsLoading = false;
            }
        },

        // Show error message on input delay
        delayTouch(v$) {
            v$.$reset();
            if (touchMap.has(v$)) {
                clearTimeout(touchMap.get(v$));
            }
            touchMap.set(v$, setTimeout(v$.$touch, 1000));
        },

        // Close search results
        closeServiceNameResults() {
            this.serviceNameResultsIsVisible = false;
            this.serviceNameResultsIsVisibleClick = false;
        },

        showServiceNameResults() {
            this.serviceNameResultsIsVisible = true;
            this.serviceNameResultsIsVisibleClick = true;
        },

        selectServiceName(val) {
            this.service.name = val;
            this.closeServiceNameResults();
        },

        // Search
        search: debounce(function(val) {
            if (this.service.name.length > 1) {
                axios.post('/search/services', {
                    search: val
                }).then((response) => {
                    this.serviceResults = response.data.searchedServices;
                })
            } else {
                this.serviceResults = [];
            }
        },150),
    },

    validations: function() {
        return {
            service: {
                name: {
                    required: helpers.withMessage("Vyplňte název služby", required),
                },

                category: {
                    required: helpers.withMessage(
                        "Vyberte kategorii služby",
                        required
                    ),
                },

                subCategory: {
                    required: helpers.withMessage(
                        "Vyberte podkategorii služby",
                        required
                    ),
                },

                price: {
                    required: helpers.withMessage("Zadajte cenu služby", required),
                    numeric: helpers.withMessage("Zadajte jen čísla", numeric),
                },
            },

            form: {
                description: {
                    //niÄŤ
                },
                name: {
                    required: helpers.withMessage(
                        "VyplĹte obchodnĂ˝ nĂˇzov firmy",
                        required
                    ),
                },
                ico: {
                    required: helpers.withMessage("VyplĹte IÄŚO", required),
                    numeric: helpers.withMessage("NeplatnĂ˝ formĂˇt IÄŚO", numeric),
                    // minLength: helpers.withMessage('NeplatnĂ˝ formĂˇt IÄŚO', minLength(10)),
                    // maxLength: helpers.withMessage('NeplatnĂ˝ formĂˇt IÄŚO', maxLength(10))
                },

                email: {
                    required: helpers.withMessage("VyplĹte e-mail", required),
                    email: helpers.withMessage("NeplatnĂ˝ e-mail", email),
                },

                // password: this.registration_page ? {
                //     required: helpers.withMessage("Zadajte heslo", required),
                //     minLength: helpers.withMessage('Heslo musĂ­ maĹĄ min. 7 znakov', minLength(7)),
                //     maxLength: helpers.withMessage('Heslo mĂ´Ĺľe maĹĄ max. 30 znakov', maxLength(30))
                // } : {},

                web: {
                    // required: helpers.withMessage('VyplĹte webovĂş adresu', required),
                    url: helpers.withMessage("NeplatnĂ˝ formĂˇt", url),
                },
                street: {
                    required: helpers.withMessage("VyplĹte ulicu", required),
                },
                // street_number: {
                //     required: helpers.withMessage("VyplĹte ÄŤĂ­slo ulice", required),
                // },
                city: {
                    required: helpers.withMessage("VyplĹte mesto", required),
                },
                post_code: {
                    required: helpers.withMessage("VyplĹte PSÄŚ", required),
                    // numeric: helpers.withMessage("NeplatnĂ© PSÄŚ", numeric),
                },
                country: {
                    required: helpers.withMessage("VyplĹte krajinu", required),
                },
                mobile: {
                    required: helpers.withMessage("VyplĹte mobil", required),
                    numeric: helpers.withMessage(
                        "NeplatnĂ˝ formĂˇt. Zadajte ju v tvare 090xxxxxxx",
                        numeric
                    ),
                    // minLength: helpers.withMessage('NeplatnĂ˝ formĂˇt. Zadajte ju v tvare 0905xxxxxx', minLength(10)),
                    // maxLength: helpers.withMessage('NeplatnĂ˝ formĂˇt. Zadajte ju v tvare 0905xxxxxx', maxLength(10))
                },
                phone: {
                    // required: helpers.withMessage('VyplĹte telefĂłn', required),
                    // numeric: helpers.withMessage('NeplatnĂ˝ formĂˇt', numeric)
                },

                google_profile: {
                    url: helpers.withMessage("NeplatnĂ˝ formĂˇt", url),
                },

                facebook: {
                    url: helpers.withMessage("NeplatnĂ˝ formĂˇt", url),
                },

                instagram: {
                    url: helpers.withMessage("NeplatnĂ˝ formĂˇt", url),
                },

                linkedin: {
                    url: helpers.withMessage("NeplatnĂ˝ formĂˇt", url),
                },

                addedServices: {
                    required: helpers.withMessage(
                        "Pridajte aspoĹ jednu sluĹľbu",
                        required
                    ),
                },
                ... (this.registration_page ? {
                    // plan: {
                    //     required: helpers.withMessage("Vyberte predplatnĂ©", required),
                    // },
                    // period: {
                    //     required: helpers.withMessage("Vyberte obdobie", required),
                    // },
                    vop: {
                        sameAs: helpers.withMessage('ProsĂ­m, zaĹˇkrtnite toto polĂ­ÄŤko', sameAs(true))
                    },
                    personal: {
                        sameAs: helpers.withMessage('ProsĂ­m, zaĹˇkrtnite toto polĂ­ÄŤko', sameAs(true))
                    },
                } : {})
            },

            $validationGroups: {
                firstGroup: ["form.mobile", "form.phone"],
            },
        }
    },
};
</script>

<style lang="scss" scoped>
@import "resources/sass/abstracts/_mixins.scss";
@import "resources/sass/abstracts/_variables.scss";

:root {
    /* Disabled State */
    --vs-state-disabled-bg: #fff;
}

.subscription {
    margin-top: 20px;

    h3 {
        font-weight: 700;
    }
}
.opening-hours {
    margin-top: 20px;
    .error {
        position: absolute;
        left: 0;
        bottom: -28px;
        padding-left: 0;
    }
    h3 {
        font-weight: 700;
    }

    p {
        margin-top: 15px;
    }
    .form-group {
        margin-top: 0;
        margin-right: 15px;
    }
}
.file-name {
    margin-top: 15px;
    background-color: #eeeeee;
    max-width: 150px;
    text-align: center;
    padding: 10px;
    border-radius: 6px;
    p {
        font-size: rem(12px);
    }
}

.added-services {
    div {
        padding: 15px;
        background-color: #fafafa;
        border: 1px solid #eeeeee;
        border-radius: 6px;
        margin-top: 15px;
        position: relative;
        button {
            position: absolute;
            top: 15px;
            right: 15px;
            color: $color-white;
            font-size: rem(12px);
            border-radius: 4px;
            background-color: #ce0000;
            padding: 3px 7px;
            transition: opacity 0.2s ease-in-out;
            &:hover {
                opacity: 0.7;
            }
        }
    }
}

.add-service-form {
    .add,
    .reset {
        color: $color-white;
        padding: 10px 20px;
        margin: 15px 15px 0 0;
        border-radius: 6px;
        transition: all 0.2s ease-in-out;
        &:hover {
            opacity: 0.7;
        }
    }
    .add {
        background-color: #1c7430;
    }

    .reset {
        background-color: #d13830;
    }
}

form {
    max-width: 534px;
    margin: 100px auto;
    .error {
        color: #d13830;
        font-size: rem(14px);
        padding-left: 15px;
        font-weight: 600;
    }
    button[type="submit"] {
        margin-top: 30px;
    }
    .form-group {
        margin-top: 30px;
        position: relative;
        h3 {
            font-weight: 700;
            margin-bottom: 10px;
        }
        .question {
        position: relative;
    text-align: right;
    font-size: 24px;
    left: 300px;
    bottom: 20px;
    background: black;
    border: 2px solid black;
    border-radius: 50%;
    color: white;
}
        label {
            font-size: rem(11px);
            text-transform: uppercase;
            color: #9598a7;
            font-weight: 700;
            letter-spacing: 2px;
            position: absolute;
            top: 9px;
            left: 15px;
            z-index: 10;
        }
        label i {
            position: relative;
    text-align: right;
    font-size: 24px;
    left: 300px;
    bottom: 20px;
    background: black;
    border: 2px solid black;
    border-radius: 50%;
    color: white;
        }
        i {
            position: relative;
    text-align: right;
    font-size: 24px;
    left: 300px;
    bottom: 20px;
    background: black;
    border: 2px solid black;
    border-radius: 50%;
    color: white;
        }
        input:not(.vs__search) {
            width: 100%;
            height: 56px;
            border: 1px solid #dde3e8;
            border-radius: 6px;
            padding: 20px 15px 0 15px;
            font-size: rem(14px);
            outline: none;
            &:focus {
                border-color: $color-secondary;
            }
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            &[type="number"] {
                -moz-appearance: textfield;
            }
        }

        textarea:not(.vs__search) {
            width: 100%;
            height: 80px;
            border: none;
            border-radius: 0px;
            padding: 0px 15px 10px 15px;
            font-size: 0.875rem;
            outline: none;
            line-height: 1.37;
            min-height: 80px;
            margin-bottom: 0;
            display: block;
            &:focus {
                border-color: $color-secondary;
            }
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            &[type="number"] {
                -moz-appearance: textfield;
            }
        }

        .add-service {
            width: 100%;
            background-color: #f1f1f1;
            padding: 12px 0;
            font-weight: 700;
            text-transform: uppercase;
            border-radius: 6px;
            letter-spacing: 1px;
            font-size: rem(14px);
            transition: all 0.2s ease-in-out;
            &:hover {
                background-color: darken(#f1f1f1, 5%);
            }
        }

        .info-wrap {
            width: 100%;
            background-color: lighten($color-secondary, 10%);
            padding: 12px;
            text-align: center;
            font-weight: 700;
            text-transform: uppercase;
            border-radius: 6px;
            letter-spacing: 1px;
            font-size: rem(14px);
            transition: all 0.2s ease-in-out;
            a {
                width: 100%;
                height: 100%;

            }
            &:hover {
                background-color: $color-secondary;
            }
        }
    }

    .form-group.form-group-textarea {
        padding-top: 30px;
        border: 1px solid #dde3e8;
        padding-bottom: 0;
        border-radius: 6px;
    }

    .upload-file input {
        display: none;
    }

    .upload-images {
        justify-content: center;
        .upload-file:first-of-type {
            margin-right: 10px;
        }
    }
    .upload-img {
        width: 160px;
        height: 160px;
        margin: 0 auto;
        background-color: #f1f1f1;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        @include transitionBase;
        cursor: pointer;
        padding: 0 15px;
        &:hover {
            background-color: darken(#f1f1f1, 5%);
        }
        & > .flex {
            flex-direction: column;
            align-items: center;
            img {
                margin-bottom: 9px;
            }
            span {
                text-transform: uppercase;
                color: #9598a7;
                font-size: rem(11px);
                font-weight: 700;
                letter-spacing: 2px;
                text-align: center;
            }
        }
    }
}
.upload-image-h3 {
    padding: 20px 0px;
    font-weight: 700;
}

.helper-trigger {
    cursor: help;
    font-size: 30px;
    color: rgb(226, 201, 23);
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    display: block;
    transform: translate(50%, -50%);
    margin: 0;
    padding: 0;
}
/*
@media (max-width: 1400px) {
    .question {display: none;}
}
*/
.search-results {
    width: 100%;
    padding:  5px 20px;
    border: 1px solid #D1D5DB;
    border-top: none;
    max-height: 400px;
    background-color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    z-index: 100;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    overflow: auto;
    @media(max-width: 540px) {
        max-height: 300px;
    }

    ul {
        li {
            padding: 4px 0 2px 0;
            margin-bottom: 2px;
            border-bottom: 1px solid transparent;
            transition: .2s all;

            @media(max-width: 540px) {
                font-size: rem(13px);
            }

            &:hover {
                border-bottom: 1px solid #D1D5DB;
                cursor: pointer;
            }
        }
    }
}

.select-box--option {
    padding: 3px 0;
}
.select-box--title, .select-box--subtitle {
            margin-bottom: 0;
            /*font-size: 1rem;*/
            line-height: 1.2;
        }

        .select-box--title {
            /*font-size: 1rem;*/
            font-weight: bold;
        }

.vs__search {
    border: none !important;
    padding: 20px 15px 0 15px !important;
    font-size: rem(14px) !important;
    background-color: transparent !important;
}

.vs--disabled {
    .vs__dropdown-toggle,
    .vs__clear,
    .vs__search,
    .vs__selected,
    .vs__open-indicator {
        background: none !important;
        background-color: #fff !important;
    }
}

.vs__selected-options {
    .vs__search {

        background: none !important;
        background-color: transparent !important;
    }
}

</style>
