<template>
    <form @submit.prevent="verify">
        <input type="hidden" name="_token" :value="csrf">
        <div class="form-group mb-5">
            <label for="password">Heslo</label>
            <input v-model="password" type="password" name="password" id="password" class="box">
            <span v-if="v$.password.$error" class="error">{{v$.password.$errors[0].$message}}</span>
        </div>

        <div class="form-group mb-5">
            <label for="password_confirmation">Zopakujte Heslo</label>
            <input v-model="confirmPassword" type="password" name="password_confirmation" id="password_confirmation" class="box">
            <span v-if="v$.confirmPassword.$error" class="error">{{v$.confirmPassword.$errors[0].$message}}</span>
        </div>

        <button class="button" type="submit">Verifikovať</button>
    </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import {required, sameAs,helpers}  from "@vuelidate/validators";
import axios from "axios";
const touchMap = new WeakMap();

export default {
    name: "VerificationForm",
    setup: () => ({
        v$: useVuelidate(),
    }),
    props: ['id', 'hash'],

    data() {
        return {
            csrf:document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            password: '',
            confirmPassword: ''
        }
    },

    validations() {
        return {
            password: {
                required: helpers.withMessage('Heslo je povinné', required)
            },

            confirmPassword: {
                required: helpers.withMessage('Zopakovanie hesla je povinné', required),
                sameAs: helpers.withMessage('Heslá sa nezhodujú', sameAs(this.password))
            }
        }
    },

    methods: {
        resetForm() {
            this.password = '';
            this.confirmPassword = '';
            this.v$.$reset();
        },
        verify() {
            this.v$.$validate();
            if (!this.v$.$error) {
                axios.put(`/firma/verifikacia/${this.id}/${this.hash}`, {
                    password: this.password,
                    password_confirmation: this.confirmPassword
                }).then(()=> {
                    this.$swal({
                        title: 'OK',
                        text: 'Verifikácia bola úspešná',
                        timerProgressBar: true,
                        timer: 2500,
                        icon: 'success',
                        background: '#308102',
                        color: '#fff',
                        showConfirmButton: false
                    });
                    this.resetForm();
                    // window.location.href = `/firma/${this.id}`;
                    window.location.href = `/firma/nastavenia`;
                }).catch((error)=>{
                    this.resetForm();
                    this.$swal({
                        title: 'Chyba!',
                        text: (error.response.data.message ? error.response.data.message : 'Vyskúšajte neskôr prosím'),
                        timerProgressBar: true,
                        timer: 2500,
                        icon: 'error',
                        background: '#ce0000',
                        color: '#fff',
                        showConfirmButton: false
                    })
                })
            } else {
                this.$swal({
                    title: 'Chyba!',
                    text: 'Vyplňte všetky povinné údaje.',
                    toast: true,
                    position: 'top-end',
                    timerProgressBar: true,
                    timer: 2500,
                    icon: 'error',
                    background: '#ce0000',
                    color: '#fff',
                    showConfirmButton: false
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
