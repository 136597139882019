window.openSuggestionForImprovementModalSuccess = function openSuggestionForImprovementModalSuccess() {
    if (document.getElementById('suggestion-for-improvement-modal-form-success')) {
        document.getElementById('suggestion-for-improvement-modal-form-success').classList.add('open');
    }
};

window.closeSuggestionForImprovementModalSuccess = function closeSuggestionForImprovementModalSuccess() {
    if (document.getElementById('suggestion-for-improvement-modal-form-success')) {
        document.getElementById('suggestion-for-improvement-modal-form-success').classList.remove('open');
    }
};

window.openSuggestionForImprovementModal = function openSuggestionForImprovementModal() {
    closeSuggestionForImprovementModalSuccess();
    if (document.getElementById('suggestion-for-improvement-modal')) {
        document.getElementById('suggestion-for-improvement-modal').classList.add('open');
    }
};

window.closeSuggestionForImprovementModal = function closeSuggestionForImprovementModal() {
    if (document.getElementById('suggestion-for-improvement-modal')) {
        document.getElementById('suggestion-for-improvement-modal').classList.remove('open');
    }
};
