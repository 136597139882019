import "./toastify";

document.querySelectorAll('.js-contact-form').forEach((element) => {
    element.addEventListener('submit', function(e) {
        e.preventDefault();
        submitContactForm(e);
    });
});

// Contact form send SELLING FORM
document.querySelectorAll('.js-contact-form-suggestion-for-improvement').forEach((element) => {
    element.addEventListener('submit', function(e) {
        e.preventDefault();
        submitContactSuggestionForImprovementForm(e);
    });
});


// Contact form send
function submitContactForm(element) {
    let url = element.target.action;
    let btn = element.target.getElementsByTagName("button")[0];
    btn.disabled = true;
    btn.classList.add("loading");

    let formData = {};
    let form = element.target;
    let data = new FormData(form);
    for (let [key, value] of data.entries()) {
        formData[key.toString()] = value;
    }
    fetch(url, {
        method: "POST",
        credentials: "same-origin",
        mode: "same-origin",
        headers: {
            "Cache-Control": "no-cache",
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": document
                .getElementsByName("csrf-token")[0]
                .getAttribute("content"),
        },
        body: JSON.stringify(formData),
    })
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            if (data.status === 1) {
                // dataLayer.push({ event: "email_sent" });
                showToast(data.msg, "success");
                form.reset();
            } else {
                let obj = data.msg;
                let message = "";
                if (typeof obj === "object") {
                    Object.keys(obj).map(function (key) {
                        message += obj[key] + "\n";
                    });
                } else {
                    message = obj;
                }
                showToast(message, "error");
            }

            btn.disabled = false;
            btn.classList.remove("loading");

        });
}

// Contact form send
function submitContactSuggestionForImprovementForm(event) {
    let url = event.target.action;
    let btn = event.target.getElementsByTagName('button')[0];
    btn.disabled = true;

    // Append the form status
    let formStatus = document.createElement('div');
    formStatus.setAttribute('class', 'alertbox');


    let formData = {};
    let form = event.target;
    let data = new FormData(form);
    for (let [key, value] of data.entries()) {
        formData[key.toString()] = value;
    }
    fetch(url, {
        method: 'POST',
        credentials: 'same-origin',
        mode: 'same-origin',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': document
                .getElementsByName('csrf-token')[0]
                .getAttribute('content'),
        },
        body: JSON.stringify(formData),
    })
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            if (data.status === true) {
                openSuggestionForImprovementModalSuccess();
                form.reset();

                setTimeout(function() {
                    closeSuggestionForImprovementModalSuccess();
                    closeSuggestionForImprovementModal();
                }, 5000);
            }
            else {
                let obj = data.msg;
                let message = "";
                if (typeof obj === "object") {
                    Object.keys(obj).map(function (key) {
                        message += obj[key] + "\n";
                    });
                } else {
                    message = obj;
                }
                showToast(message, "error");
            }
            btn.disabled = false;
        });
}
