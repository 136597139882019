import Headroom from "../../../node_modules/headroom.js/dist/headroom";

const header = document.getElementById("site-header");
const headroom = new Headroom(header, {
    offset: header.offsetHeight / 2,
});
headroom.init();

//BURGER MENU
const headerMenu = document.getElementById("js-headerMenu");
const headerOpen = document.getElementById("js-headerOpen");
const headerClose = document.getElementById("js-headerClose");
const headerOverlay = document.getElementById("js-headerOverlay");

headerOpen.addEventListener("click", () => {
    toggleHeaderMenu();
    headroom.freeze();
});

headerOverlay.addEventListener("click", () => {
    toggleHeaderMenu();
    headroom.unfreeze();
});

headerClose.addEventListener("click", () => {
    toggleHeaderMenu();
    headroom.unfreeze();
});

function toggleHeaderMenu() {
    headerMenu.classList.toggle("header-menu--is-open");
    headerOverlay.classList.toggle("header-overlay--active");
}
