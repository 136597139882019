<template>
    <form @submit.prevent="registration">
        <div class="subscription">
            <h3>Predplatné</h3>
            <div class="form-group">
                <label>Predplatné</label>
                <vSelect
                    v-model="form.plan"
                    :reduce="plan => plan.id"
                    :options="plans"
                    label="name"
                    :clearable="false"
                    :searchable="false"
                />
                <span v-if="v$.form.plan.$error" class="error">{{
                        v$.form.plan.$errors[0].$message
                    }}</span>
            </div>

            <div class="form-group" v-if="this.form.plan">
                <label>Obdobie</label>
                <vSelect
                    v-model="form.period"
                    :reduce="period => period.months"
                    :options="period"
                    label="title"
                    :clearable="false"
                    :searchable="false"
                />
                <span v-if="v$.form.period.$error" class="error">{{
                        v$.form.period.$errors[0].$message
                    }}</span>
            </div>
        </div>

        <div class="flex justify-center">
            <button type="submit" class="button">
                Predĺžiť predplatné
            </button>
        </div>
    </form>
</template>

<script>
import "vue-select/dist/vue-select.css";
import { useVuelidate } from "@vuelidate/core";
import {
    required,
    helpers,
} from "@vuelidate/validators";
import axios from "axios";
const touchMap = new WeakMap();

export default {
    setup: () => ({
        v$: useVuelidate(),
    }),
    name: "SubscriptionForm",
    props: {
        endpoint: String,
        plans: Array,
    },
    data() {
        return {
            csrf: document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute("content"),
            form: {
                period: null,
                plan: null,
            },
            period: [],
        };
    },

    created() {
        if (this.plans.length === 1) {
            this.form.plan = this.plans[0];
        }
    },

    watch: {
        'form.plan': function (val, oldVal) {
            this.period = [];
            if (val) {
                let newPeriod = [];
                this.plans.forEach(function (itemPlan) {
                    if(parseInt(itemPlan.id) === parseInt(val)) {
                        newPeriod = itemPlan.prices;
                    }
                });
                this.period = newPeriod;
            }
        },
    },

    methods: {

        // Reset form
        resetForm() {
            this.form.plan = null;
            this.form.period = null;
            this.v$.form.$reset();
        },

        // Registration
        registration() {
            this.v$.form.$validate();
            console.log(this.v$.form)
            if (!this.v$.form.$error) {
                const formData = new FormData();

                for (let key in this.form) {
                    Array.isArray(this.form[key])
                        ? this.form[key].forEach((value) =>
                            formData.append(key + "[]", JSON.stringify(value))
                        )
                        : formData.append(key, this.form[key]);
                }

                axios
                    .post(this.endpoint, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            "X-CSRF-TOKEN": this.csrf,
                        },
                    })
                    .then((response) => {
                        this.$swal({
                            title: "Ďakujeme!",
                            text: "Registrácia prebehla úspešne.",
                            timerProgressBar: true,
                            timer: 2500,
                            icon: "success",
                            background: "#308102",
                            color: "#fff",
                            showConfirmButton: false,
                        });
                        this.resetForm();

                        window.location = response.request.responseURL;
                    })
                    .catch((e) => {
                        // Error
                        this.$swal({
                            title: "Chyba!",
                            text: "Vyskúšajte neskôr prosím.",
                            timerProgressBar: true,
                            timer: 2500,
                            icon: "error",
                            background: "#ce0000",
                            color: "#fff",
                            showConfirmButton: false,
                        });
                    });
            } else {
                this.$nextTick(() => {
                    const domRect = document
                        .querySelector(".error")
                        .getBoundingClientRect();
                    window.scrollTo({
                        top:
                            domRect.top +
                            document.documentElement.scrollTop -
                            65,
                        left:
                            domRect.left +
                            document.documentElement.scrollLeft -
                            115,
                        behavior: "smooth",
                    });
                });

                this.$swal({
                    title: "Chyba!",
                    text: "Vyplňte všetky povinné údaje.",
                    toast: true,
                    position: "top-end",
                    timerProgressBar: true,
                    timer: 2500,
                    icon: "error",
                    background: "#ce0000",
                    color: "#fff",
                    showConfirmButton: false,
                });
            }
        },

        // Show error message on input delay
        delayTouch(v$) {
            v$.$reset();
            if (touchMap.has(v$)) {
                clearTimeout(touchMap.get(v$));
            }
            touchMap.set(v$, setTimeout(v$.$touch, 1000));
        },
    },

    validations: function() {
        return {
            form: {
                plan: {
                    required: helpers.withMessage("Vyberte predplatné", required),
                },
                period: {
                    required: helpers.withMessage("Vyberte obdobie", required),
                },
            }
        }
    },
};
</script>

<style lang="scss" scoped>
@import "resources/sass/abstracts/_mixins.scss";
@import "resources/sass/abstracts/_variables.scss";

.subscription {
    margin-top: 20px;

    h3 {
        font-weight: 700;
    }
}

form {
    max-width: 534px;
    margin: 100px auto;
    .error {
        color: #d13830;
        font-size: rem(14px);
        padding-left: 15px;
        font-weight: 600;
    }
    button[type="submit"] {
        margin-top: 30px;
    }
    .form-group {
        margin-top: 30px;
        position: relative;
        h3 {
            font-weight: 700;
            margin-bottom: 10px;
        }
        label {
            font-size: rem(11px);
            text-transform: uppercase;
            color: #9598a7;
            font-weight: 700;
            letter-spacing: 2px;
            position: absolute;
            top: 9px;
            left: 15px;
            z-index: 10;
        }
        input {
            width: 100%;
            height: 56px;
            border: 1px solid #dde3e8;
            border-radius: 6px;
            padding: 20px 15px 0 15px;
            font-size: rem(14px);
            outline: none;
            &:focus {
                border-color: $color-secondary;
            }
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            &[type="number"] {
                -moz-appearance: textfield;
            }
        }
    }
}
</style>
